import { useState, useEffect, FC, useCallback } from 'react';
import throttle from 'lodash-es/throttle';

import Button from '../../Button';
import styles from './ScreenSizeWarning.module.css';

const ScreenSizeWarning: FC<{
  width: number;
  backgroundImageUrl: string;
  targetDomId: string;
  shareUrl?: string;
  isDemoEmbedded: boolean;
}> = ({ width, backgroundImageUrl, shareUrl, isDemoEmbedded }) => {
  const [isIgnore, setIsIgnore] = useState(false);
  const [isShow, setIsShow] = useState(false);

  useEffect(() => {
    if (!isIgnore) {
      const handleResize = throttle(
        () => {
          setIsShow(window.innerWidth <= width);
        },
        300,
        {
          leading: false,
          trailing: true,
        }
      );
      // initial invoke
      handleResize();

      window.addEventListener('resize', handleResize, true);
      return () => {
        window.removeEventListener('resize', handleResize, true);
      };
    }
  }, [width, isIgnore]);

  const handleFullScreenButtonClick = useCallback(() => {
    window.open(`${shareUrl}`, '_blank');
  }, [shareUrl]);

  if (isIgnore || !isShow) return null;

  return (
    <div
      className={styles.root}
      style={{ backgroundImage: `url(${backgroundImageUrl})` }}
    >
      <h1 className={styles.title}>
        Your screen size looks too small for the demo experience
      </h1>

      {!isDemoEmbedded && (
        <Button variant="outline" onClick={() => setIsIgnore(true)}>
          I Can Continue
        </Button>
      )}
      {isDemoEmbedded && (
        <Button variant="outline" onClick={handleFullScreenButtonClick}>
          View Full Screen
        </Button>
      )}
    </div>
  );
};

export default ScreenSizeWarning;

import { useState, useMemo, useCallback, VFC } from 'react';
import { useForm } from 'react-hook-form';
import { emailRegex } from 'shared-components/src/features/ui/ui.constants';
import FormInput from 'shared-components/src/components/FormInput';

import styles from './DefaultLeadForm.module.css';
import { PublishedInternalFormConfig } from './LeadForm.types';
import { PublicDemoLead, PublicDemoLeadSource } from '../PublicDemoPage.types';

const fieldsOrder: PublishedInternalFormConfig['internal_form']['fields'] = [
  'first_name',
  'last_name',
  'email',
  'company_name',
  'phone',
];

interface LeadFormProps {
  onSubmit: (lead: PublicDemoLead) => void;
  formConfig: PublishedInternalFormConfig['internal_form'];
}

const LeadForm: VFC<LeadFormProps> = ({ onSubmit, formConfig }) => {
  const [isLoading, setIsLoading] = useState(false);

  const { register, handleSubmit, formState } = useForm<{
    email: string;
    first_name: string;
    last_name: string;
    phone: string;
    company_name: string;
  }>({
    mode: 'onChange',
  });

  const formFields = useMemo(
    () =>
      formConfig.fields.reduce((acc, el) => {
        const index = fieldsOrder.findIndex((f) => f === el);
        acc[index] = el;
        return acc;
      }, [] as Partial<PublishedInternalFormConfig['internal_form']['fields']>),
    [formConfig.fields]
  );

  const handleFormSubmit = useCallback(
    (formData) => {
      try {
        setIsLoading(true);
        onSubmit({
          ...formData,
          /**
           * @see {@link https://storylane.atlassian.net/wiki/spaces/ENGINEERIN/pages/393227/Public+Demo#Lead-source Docs}
           * @see {@link https://storylane.atlassian.net/browse/STORY-3080 Jira task}
           */
          source: PublicDemoLeadSource.InternalForm,
        });
      } catch (error) {
        setIsLoading(false);
        console.error(error);
      }
    },
    [onSubmit]
  );

  return (
    <div className={styles.root}>
      <h1 className={styles.header}>
        {formConfig.title || 'Try our Product Demo'}
      </h1>
      <form
        onSubmit={handleSubmit(handleFormSubmit)}
        className={styles.formGrid}
        data-testid="lead-form"
      >
        {formFields.map((item) => {
          switch (item) {
            case 'email':
              return (
                <FormInput
                  label="Work Email"
                  error={formState.errors.email?.message}
                  type="email"
                  className={styles.emailField}
                  key="email"
                  placeholder="john.doe.work@company.com"
                  {...register('email', {
                    required: 'Email is required',
                    pattern: {
                      value: emailRegex,
                      message: 'Invalid email',
                    },
                  })}
                />
              );
            case 'company_name':
              return (
                <FormInput
                  label="Company Name"
                  error={formState.errors.company_name?.message}
                  key="company_name"
                  className={styles.companyField}
                  {...register('company_name', {
                    required: 'Company name is required',
                  })}
                />
              );
            case 'first_name':
              return (
                <FormInput
                  label="First Name"
                  error={formState.errors.first_name?.message}
                  key="first_name"
                  placeholder="John"
                  {...register('first_name', {
                    required: 'First Name is required',
                    maxLength: {
                      value: 100,
                      message: 'First name is too long',
                    },
                  })}
                />
              );
            case 'last_name':
              return (
                <FormInput
                  label="Last Name"
                  error={formState.errors.last_name?.message}
                  key="last_name"
                  placeholder="Doe"
                  {...register('last_name', {
                    required: 'Last Name is required',
                    maxLength: {
                      value: 100,
                      message: 'Last name is too long',
                    },
                  })}
                />
              );
            case 'phone':
              return (
                <FormInput
                  label="Mobile"
                  error={formState.errors.phone?.message}
                  className={styles.phoneField}
                  key="phone"
                  {...register('phone', {
                    required: 'Mobile is required',
                    maxLength: {
                      value: 100,
                      message: 'Last name is too long',
                    },
                  })}
                />
              );
          }
        })}
        <button
          className={styles.submit}
          type="submit"
          disabled={isLoading || !formState.isValid || formState.isSubmitting}
        >
          {formConfig.cta || 'Continue'}
        </button>
      </form>
    </div>
  );
};

export default LeadForm;

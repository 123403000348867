import { VFC } from 'react';
import { ProjectId } from 'shared-components/src/features/project/project.types';
import VendorLeadForm from 'shared-components/src/components/widgets/VendorLeadForm';
import Watermark from 'shared-components/src/components/ui/Watermark';

import DefaultLeadForm from './DefaultLeadForm';
import styles from './LeadForm.module.css';
import { isPublishedInternalLeadForm } from './LeadForm.utils';
import { PublishedFormConfig } from './LeadForm.types';
import { PublicDemoLead, PublicDemoLeadSource } from '../PublicDemoPage.types';

interface LeadFormProps {
  data: PublishedFormConfig;
  projectId: ProjectId;
  onSubmit: (lead: PublicDemoLead) => void;
  showWatermark: boolean;
  watermarkLogoHref: string;
}

const LeadForm: VFC<LeadFormProps> = ({
  data,
  projectId,
  onSubmit,
  showWatermark,
  watermarkLogoHref,
}: LeadFormProps) => {
  return (
    <>
      {isPublishedInternalLeadForm(data) ? (
        <DefaultLeadForm onSubmit={onSubmit} formConfig={data.internal_form} />
      ) : (
        <VendorLeadForm
          projectId={projectId}
          onSubmit={({ lead }) =>
            onSubmit({
              ...lead,
              /**
               * @see {@link https://storylane.atlassian.net/wiki/spaces/ENGINEERIN/pages/393227/Public+Demo#Lead-source Docs}
               * @see {@link https://storylane.atlassian.net/browse/STORY-3080 Jira task}
               */
              source: PublicDemoLeadSource.VendorForm,
            })
          }
          width={data.internal_form.width}
          height={data.internal_form.height}
          backgroundColor={data.internal_form.backgroundColor}
          /**
           * The "old" custom lead form is shown BEFORE we get "host" data,
           * hence we don't set it here.
           *
           * @see {@link https://storylane.atlassian.net/l/cp/e2mwPUCL [Draft] Docs}
           */
          urlQuery={undefined}
        />
      )}
      {showWatermark && (
        <div className={styles.watermark}>
          <Watermark testId="lead-form-watermark" href={watermarkLogoHref} />
        </div>
      )}
    </>
  );
};

export default LeadForm;

import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import cx from 'classnames';
import Head from 'next/head';
import { captureException } from '@sentry/react';
import { ProjectKind } from 'shared-components/src/features/project/project.constants';
import { PageId } from 'shared-components/src/features/projectPages/projectPages.types';
import { SubstitutionKind } from 'shared-components/src/features/substitution/substitution.constants';
import { PublishedSubstitutionImage } from 'shared-components/src/features/substitution/substitution.types';
import { WidgetId } from 'shared-components/src/features/widgets/widgets.types';
import DemoPlayer, {
  DemoPlayerConfig,
  DemoPlayerImageSubstitutionsDict,
  DemoPlayerTextSubstitutionsDict,
  DemoPlayerLead,
  DemoPlayerEventEmitter,
  demoplayerEventCreators,
} from 'demoplayer';
import {
  getSubstitutionsFromLead,
  wrapSubstitutionKey,
} from 'shared-components/src/features/substitution/substitution.utils';
import { Routes } from 'shared-components/src/features/router/router.constants';
import ScreenSizeWarning from 'shared-components/src/components/ui/ScreenSizeWarning';
import ResponsiveRootStyle from 'shared-components/src/components/ResponsiveRootStyle';
import { useRouter } from 'next/router';
import {
  getSessionAudioAutoplay,
  setSessionAudioAutoplay,
} from 'shared-components/src/features/audioSession/audioSession.utils';
import { useSearchParam } from 'shared-components/src/hooks/react-use/useSearchParam';
import { stringToUrl } from 'shared-components/src/utils/stringToUrl';

import styles from './PublicDemoPage.module.css';
import LeadForm from './LeadForm';
import { GetSharedProjectResponse } from './api/project.api';
import { getSearchParams } from 'utils/getSearchParams';
import {
  useCrossOriginCommunication,
  useIsDemoEmbedded,
  useAnalytics,
  usePublicDemoHostInfo,
  usePublicDemoMachine,
  useQueryFlags,
  useQuerySubstitutionsText,
  useSharePageHistoryState,
  useDocumentCustomScripts,
  useVendorAnalytics,
} from './PublicDemoPage.hooks';
import {
  EVENT_TYPE,
  PublicDemoMachineStateValue,
} from './PublicDemoPage.machine';
import { isHtmlScaleEnabled } from 'utils/isHtmlScaleEnabled';
import {
  ExtUrlTarget,
  PublicDemoLead,
  PublicDemoLeadSource,
} from './PublicDemoPage.types';
import HeadCustomFont from 'components/HeadCustomFont';
import { rootContainerId } from './PublicDemoPage.constants';
import GoogleAnalyticsScript from 'components/GoogleAnalyticsScript';

interface PublicDemoPageProps {
  demoId: string;
  responseData: GetSharedProjectResponse;
  baseUrl: string;
}

const demoPlayerEventEmitter = new DemoPlayerEventEmitter();

const PublicDemoPage: FC<PublicDemoPageProps> = ({
  demoId,
  responseData,
  baseUrl,
}) => {
  const router = useRouter();
  const search = getSearchParams(router.asPath).toString();

  const queryFlags = useQueryFlags({ search });

  const {
    recording,
    query,
    forceHideSteps,
    scale: queryFlagScale,
    forceStartFlowIndex,
    autoPlay,
    disableAnimations,
  } = queryFlags;
  const [state, send] = usePublicDemoMachine({
    demoId,
    baseUrl: process.env.NEXT_PUBLIC_BASE_URL || '',
    search,
    publishedProject: responseData,
    path: router.asPath,
  });

  const flowlist = recording ? null : state.context.project?.flowlists[0];

  const hostInfo = usePublicDemoHostInfo({ search });

  // TODO: confluece...why we're doing this
  const shareUrl = `${baseUrl}${router.asPath.replace('/demo', '/share')}`;

  const {
    textSubstitutions: crossOriginTextSubstitutions,
    onInit: onInitCrossOrigin,
    onLeadCapture: onLeadCaptureCrossOrigin,
    onOpenExternalUrl: onOpenExternalUrlCrossOrigin,
    onCustomCtaExternalUrl: onCustomCtaExternalUrlCrossOrigin,
    onWalkthroughFinished: onWalkthroughFinishedCrossOrigin,
    onRecordingStop: onRecordingStopCrossOrigin,
  } = useCrossOriginCommunication({
    project: state.context.project,
    flows: state.context.flows,
    widgets: state.context.widgets,
    demoId,
    query,
    enabled: state.matches(PublicDemoMachineStateValue.Playback),
    baseUrl: process.env.NEXT_PUBLIC_BASE_URL || '',
    demoUrl: shareUrl,
    demoPlayerEventEmitter,
    enabledRecordingStartEvent: recording,
    enabledWidgetReadyEvent: recording,
  });

  const gaId = state.context.project.ga_measurement_id;
  const {
    onInit: onInitVendorAnalytics,
    onLeadCapture: onLeadCaptureVendorAnalytics,
    onOpenExternalUrl: onOpenExternalUrlVendorAnalytics,
    onCustomCtaExternalUrl: onCustomCtaExternalUrlVendorAnalytics,
    onWalkthroughFinished: onWalkthroughFinishedVendorAnalytics,
  } = useVendorAnalytics({
    demoPlayerEventEmitter,
    isGAEnabled: Boolean(gaId),
    isGTMEnabled: false,
    project: state.context.project,
    flows: state.context.flows,
    widgets: state.context.widgets,
    demoUrl: shareUrl,
    baseUrl: process.env.NEXT_PUBLIC_BASE_URL || '',
    demoId,
    query,
  });
  const waitUntilInViewport = true; // TODO: https://storylane.atlassian.net/browse/STORY-3487
  const { onOpenExternalUrl: onOpenExternalUrlAnalytics } = useAnalytics({
    project: state.context.project,
    query,
    demoId,
    hostInfo,
    baseUrl: process.env.NEXT_PUBLIC_BASE_URL || '',
    demoPlayerEventEmitter,
    waitUntilInViewport,
    disabled: recording,
  });

  useEffect(() => {
    if (state.context.project) {
      onInitCrossOrigin();
      onInitVendorAnalytics();
    }
  }, [onInitCrossOrigin, onInitVendorAnalytics, state.context.project]);

  useEffect(() => {
    return demoPlayerEventEmitter.on(
      demoplayerEventCreators.walkthroughFinishedEvent,
      () => {
        send({
          type: EVENT_TYPE.FinishWalkthrough,
        });
        onWalkthroughFinishedCrossOrigin();
        onWalkthroughFinishedVendorAnalytics();
        if (recording) {
          onRecordingStopCrossOrigin();
        }
      }
    );
  }, [
    send,
    onWalkthroughFinishedCrossOrigin,
    onWalkthroughFinishedVendorAnalytics,
    onRecordingStopCrossOrigin,
    recording,
  ]);

  const handleLeadCapture = useCallback(
    (inputLead: PublicDemoLead | DemoPlayerLead) => {
      const lead = {
        ...inputLead,
        source: inputLead.source as PublicDemoLeadSource,
      };
      send({ type: EVENT_TYPE.LeadCreated, lead });
      // TODO: send lead capture analytics using analytics hooks, but not from publicDemo.machine
      onLeadCaptureCrossOrigin(lead);
      onLeadCaptureVendorAnalytics(lead);
    },
    [send, onLeadCaptureCrossOrigin, onLeadCaptureVendorAnalytics]
  );
  useEffect(() => {
    return demoPlayerEventEmitter.on(
      demoplayerEventCreators.leadCaptureEvent,
      (arg) => handleLeadCapture(arg.inputLead)
    );
  }, [handleLeadCapture]);

  const handleOpenExternalUrl = useCallback(
    (data: { url: string; target: ExtUrlTarget }, widgetId?: WidgetId) => {
      try {
        const _data = { ...data };

        /**
         * Merge host or page query params and target url
         * https://storylane.atlassian.net/browse/STORY-1522
         */
        if (hostInfo.current?.query && _data.url) {
          const newUrl = new URL(_data.url);
          const targetUrlSearchParams = new URLSearchParams(
            hostInfo.current.query
          );

          // append missing search params
          for (const [key, value] of targetUrlSearchParams) {
            if (!newUrl.searchParams.has(key)) {
              newUrl.searchParams.append(key, value);
            }
          }

          _data.url = newUrl.toString();
        }

        // send analytics only if external url is opened from widget
        if (widgetId) {
          onOpenExternalUrlAnalytics(_data.url, widgetId);
        }
        onOpenExternalUrlCrossOrigin(_data, widgetId);
        onOpenExternalUrlVendorAnalytics(_data, widgetId);

        /**
         * Backward compatibility:
         * Old projects are embedded without `storylane.js`, so there is no script to handle "external url opening",
         * hence we do it here.
         * @see {@link https://storylane.atlassian.net/l/cp/WLmaeMRj External url issue docs}
         */
        if (_data.target === '_blank') {
          window.open(_data.url, _data.target);
        }
      } catch (e) {
        captureException(e);
      }
    },
    [
      hostInfo,
      onOpenExternalUrlAnalytics,
      onOpenExternalUrlCrossOrigin,
      onOpenExternalUrlVendorAnalytics,
    ]
  );

  useEffect(() => {
    demoPlayerEventEmitter.on(
      demoplayerEventCreators.customCtaClickEvent,
      ({ data, projectId, widgetId }) => {
        try {
          const _data = { ...data };

          if (_data.url) {
            _data.url = stringToUrl(_data.url);
          }
          try {
            if (_data?.url) {
              new URL(_data.url);
            }
          } catch (error) {
            console.error(`Invalid absolute url: ${_data.url}`);
          }

          /**
           * Merge host or page query params and target url
           * https://storylane.atlassian.net/browse/STORY-1522
           */
          if (hostInfo.current?.query && _data.url) {
            const newUrl = new URL(_data.url);
            const targetUrlSearchParams = new URLSearchParams(
              hostInfo.current.query
            );

            // append missing search params
            for (const [key, value] of targetUrlSearchParams) {
              if (!newUrl.searchParams.has(key)) {
                newUrl.searchParams.append(key, value);
              }
            }

            _data.url = newUrl.toString();
          }

          onOpenExternalUrlAnalytics(_data.url, undefined, projectId);
          onCustomCtaExternalUrlCrossOrigin(_data, widgetId);
          onCustomCtaExternalUrlVendorAnalytics(_data, widgetId);
          onOpenExternalUrlCrossOrigin(_data, widgetId);

          /**
           * Backward compatibility:
           * Old projects are embedded without `storylane.js`, so there is no script to handle "external url opening",
           * hence we do it here.
           * @see {@link https://storylane.atlassian.net/l/cp/WLmaeMRj External url issue docs}
           */
          if (_data.target === '_blank' && _data.url) {
            window.open(_data.url, _data.target);
          }
        } catch (e) {
          captureException(e);
        }
      }
    );
  }, [
    hostInfo,
    onCustomCtaExternalUrlCrossOrigin,
    onOpenExternalUrlAnalytics,
    onOpenExternalUrlCrossOrigin,
    onCustomCtaExternalUrlVendorAnalytics,
  ]);

  const isResponsiveEnabled = Boolean(
    state.context.project?.kind === ProjectKind.Html
      ? state.context.project?.feature_flags.responsive_html_demo_player
      : state.context.project?.feature_flags.responsive_image_demo_player
  );
  const isVendorLeadFormScaleEnabled =
    state.context.project?.feature_flags.autoscale_vendor_form;
  const showScreenSizeWarning =
    state.context.project?.display_config.min_width_warning && !recording;
  const whitelabel = state.context.project?.whitelabel;
  const watermarkLogoHref = `${process.env.NEXT_PUBLIC_HOST_URL}${Routes.SignUp}?utm_source=product&utm_medium=in-app&utm_campaign=made-with-storylane-logo`;
  const [audioAutoplayState, setAudioAutoplayState] = useState(() =>
    getSessionAudioAutoplay()
  );
  const setIsAudioAutoplayEnabled = useCallback((isEnabled) => {
    setSessionAudioAutoplay(isEnabled);
    setAudioAutoplayState(isEnabled);
  }, []);
  const isZoomInAnimationEnabled =
    state.context.project?.kind === ProjectKind.Image && !disableAnimations;
  const pageIdFromSearchParams = useSearchParam('page_id');
  const startPageId =
    pageIdFromSearchParams || (state.context.project?.start_page_id as PageId);

  const demoPlayerConfig = useMemo<DemoPlayerConfig>(
    () => ({
      isResponsiveEnabled,
      isWalkthroughTourDisabled: forceHideSteps,
      startFlowIndex: forceStartFlowIndex,
      startPageId,
      isLeadCaptured: Boolean(state.context.lead),
      replayButton: whitelabel && {
        backgroundColor: whitelabel.replay_demo_bg_color,
        value: whitelabel.replay_demo_text,
        color: whitelabel.replay_demo_color,
      },
      resumeButton: whitelabel && {
        backgroundColor: whitelabel.resume_demo_bg_color,
        value: whitelabel.resume_demo_text,
        color: whitelabel.resume_demo_color,
      },
      isWidgetAnimationDisabled: disableAnimations,
      isWidgetScrollAnimationDisabled: disableAnimations,
      isWidgetTranslationAnimationEnabled: Boolean(
        state.context.project?.translation_animation_enabled
      ),
      isKeepScrollBetweenWidgets:
        state.context.project.feature_flags.keep_demo_scroll_position,
      isHtmlPreRenderingEnabled:
        state.context.project.feature_flags.pre_render_next_page,
      isShadowDomEnabled:
        state.context.project.feature_flags.shadow_dom_editing,
      isRendererHtmlScaleEnabled: Boolean(
        state.context.project &&
          isHtmlScaleEnabled({
            displayConfigRendererDimensions:
              state.context.project.display_config.renderer_dimensions,
            startPageDimensions: state.context.project.pages[0].dimensions,
            queryFlagScale,
            featureFlagScale: state.context.project.feature_flags.auto_scale,
          })
      ),
      isVendorLeadFormScaleEnabled,
      watermarkLogoHref,
      watermarkTextHref: `${process.env.NEXT_PUBLIC_HOST_URL}${Routes.SignUp}?utm_source=product&utm_medium=in-app&utm_campaign=made-with-storylane-text`,
      shouldAddWatermark: Boolean(whitelabel?.brand_name_enabled),
      shouldDisplayBrandWatermark: Boolean(whitelabel?.product_logo_enabled),
      isReplayEnabled: state.context.project?.display_config.replay ?? true,
      isRecording: Boolean(recording),
      autoPlay,
      isAudioAutoplayEnabled: audioAutoplayState,
      setIsAudioAutoplayEnabled,
    }),
    [
      isResponsiveEnabled,
      forceHideSteps,
      forceStartFlowIndex,
      state.context.project,
      state.context.lead,
      whitelabel,
      disableAnimations,
      isVendorLeadFormScaleEnabled,
      watermarkLogoHref,
      recording,
      autoPlay,
      audioAutoplayState,
      setIsAudioAutoplayEnabled,
      queryFlagScale,
      startPageId,
    ]
  );

  const querySubstitutions = useQuerySubstitutionsText({ search });
  const demoPlayerTextSubstitutions = useMemo(() => {
    const leadSubstitutions = state.context.lead
      ? getSubstitutionsFromLead(state.context.lead)
      : {};
    const textSubstitutions = state.context.substitutions
      .filter((substitution) => substitution.kind === SubstitutionKind.Text)
      .reduce<DemoPlayerTextSubstitutionsDict>((dict, { key, value }) => {
        if (value) dict[`${wrapSubstitutionKey(key)}`] = value;
        return dict;
      }, {});
    const demoCrossOriginTextSubstitutions = Object.keys(
      crossOriginTextSubstitutions
    ).reduce<DemoPlayerTextSubstitutionsDict>((dict, key) => {
      dict[`${wrapSubstitutionKey(key)}`] = crossOriginTextSubstitutions[key];
      return dict;
    }, {});

    return {
      // order is important
      ...textSubstitutions,
      ...querySubstitutions,
      ...demoCrossOriginTextSubstitutions,
      ...leadSubstitutions,
    };
  }, [
    state.context.substitutions,
    state.context.lead,
    querySubstitutions,
    crossOriginTextSubstitutions,
  ]);

  const { isDemoEmbedded } = useIsDemoEmbedded({ baseUrl, demoId });

  const demoPlayerImageSubstitutions = useMemo(() => {
    return state.context.substitutions
      .filter(
        (substitution): substitution is PublishedSubstitutionImage =>
          substitution.kind === SubstitutionKind.Image
      )
      .reduce<DemoPlayerImageSubstitutionsDict>((dict, substitutionImage) => {
        dict[substitutionImage.id] = {
          ...substitutionImage,
        };
        return dict;
      }, {});
  }, [state.context.substitutions]);

  useSharePageHistoryState({
    demoPlayerEventEmitter,
    startPageId,
  });

  useDocumentCustomScripts({
    scripts: state.context.project.scripts,
  });

  return (
    <>
      <HeadCustomFont font={state.context.project?.display_config.font} />
      <Head>
        {ResponsiveRootStyle({
          isResponsiveDemoPlayerEnabled: isResponsiveEnabled,
        })}
        {state.context.project?.display_config.mobile_viewport ? (
          <meta name="viewport" content="width=device-width, initial-scale=1" />
        ) : (
          <meta name="viewport" content="maximum-scale=1.0" />
        )}
      </Head>
      {gaId && <GoogleAnalyticsScript gaId={gaId} />}
      <div
        className={styles.root}
        data-zoomin={isZoomInAnimationEnabled}
        style={{
          fontFamily: 'var(--project-font-family)',
        }}
        id={rootContainerId}
      >
        {state.matches(PublicDemoMachineStateValue.LeadFormShown) && (
          <LeadForm
            data={state.context.leadFormConfig}
            projectId={state.context.project.id}
            onSubmit={handleLeadCapture}
            showWatermark={
              state.context.project.whitelabel.product_logo_enabled
            }
            watermarkLogoHref={watermarkLogoHref}
          />
        )}
        {(state.matches(PublicDemoMachineStateValue.Playback) ||
          state.matches(PublicDemoMachineStateValue.LeadFormShown)) && (
          <div
            className={cx(styles['demo-container'], {
              [styles['demo-container--paused']]: state.matches(
                // @ts-expect-error TODO: xstate typings behave incorrect
                PublicDemoMachineStateValue.LeadFormShown
              ),
            })}
          >
            <DemoPlayer
              project={state.context.project}
              projectFlowlist={flowlist || null}
              projectFlows={state.context.flows}
              textSubstitutions={demoPlayerTextSubstitutions}
              imageSubstitutions={demoPlayerImageSubstitutions}
              projectWidgets={state.context.widgets}
              pages={state.context.project.pages}
              startPlayback={state.matches(
                // @ts-expect-error TODO: xstate typings behave incorrect
                PublicDemoMachineStateValue.Playback
              )}
              onOpenExtUrl={handleOpenExternalUrl}
              config={demoPlayerConfig}
              hostInfo={hostInfo}
              emitter={demoPlayerEventEmitter}
            />
          </div>
        )}
        {state.matches(PublicDemoMachineStateValue.Playback) &&
          showScreenSizeWarning && (
            <ScreenSizeWarning
              width={state.context.project.display_config.min_width}
              backgroundImageUrl={state.context.project.pages[0].thumbnail_url}
              targetDomId={rootContainerId}
              shareUrl={shareUrl}
              isDemoEmbedded={isDemoEmbedded}
            />
          )}
      </div>
    </>
  );
};

export default PublicDemoPage;

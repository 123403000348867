import { VFC } from 'react';
import Script from 'next/script';

interface GoogleAnalyticsScriptProps {
  gaId: string;
}
/** TODO: consider usgin @next/third-parties with Next.js v14 */
const GoogleAnalyticsScript: VFC<GoogleAnalyticsScriptProps> = (props) => {
  const { gaId } = props;
  return (
    <>
      <Script
        async
        src={`https://www.googletagmanager.com/gtag/js?id=${gaId}`}
      />
      <Script id="ga-script">
        {`window.dataLayer = window.dataLayer || [];function gtag(){dataLayer.push(arguments);}gtag('js', new Date());gtag('config', '${gaId}', { 'cookie_flags': 'samesite=none;secure' });`}
      </Script>
    </>
  );
};

export default GoogleAnalyticsScript;

import {
  PublishedFormConfig,
  PublishedInternalFormConfig,
} from './LeadForm.types';

export const isPublishedInternalLeadForm = (
  form: PublishedFormConfig
): form is PublishedInternalFormConfig => {
  return form.source === 'internal';
};
